@import '../../../Styles/settings';

.root {
  flex: 0 0 100%;
  &:before {
    bottom: 100%;
  }
}

.root,
.cart {
  position: relative;
}

.cart {
  &:before {
    top: 100%;
  }
}

.rootActive {
  padding: 0;
  &:before {
    display: none;
  }
}

.editBtnWrapper {
  margin-top: 48px;
  padding-left: 16px;
  display: flex;
  font-size: 16px;
  @include HelveticaMedium;
}

.paymentBtnsWrapper {
  padding: 10px 0 0;
}

.creditCardIcon {
  stroke: $color-brand;
}

.creditcardText {
  @include HelveticaMedium;
  font-size: 14px;
  color: $color-brand;
}

.cartSum {
  display: flex;
  flex-wrap: wrap;
}

.col {
  max-width: 100%;
  flex: 0 0 100%;
}

.paymentContainer {
  padding: 16px 16px 24px 16px;
  width: 100%;

  &:last-of-type {
    border-bottom: 1px solid $bona-light-grey;
  }
}

.hide {
  display: none;
}

.editIcon {
  fill: $midnight-blue;
  margin-left: 10px;
  width: 15px;
  height: 15px;
}

.cardContainer {
  margin: 0 -5px 10px;
  flex: 0 0 calc(100% + 10px);
}

.costContainer {
  margin-bottom: 10px;
  padding-top: 10px;
  margin-left: auto;
}

.slideDownOuter {
  height: 70px;
  position: relative;
  overflow-y: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  display: block;
}

.slideDownExpanded {
  height: auto;
}

.expandBtn {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: transparent;
  border: 0;
  font-family: $font-family;
  font-weight: 300;
  font-size: calc(1rem - 2px);
  min-height: 30px;
  padding: 0;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(100% + 20px);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) calc(100% - 20px),
      rgba(255, 255, 255, 0) 100%
    );
    z-index: -1;
  }
}

.billLaterContainer {
  width: 100%;
  margin-top: 42px;
  margin-left: 15px;
  .payByBankTransfer {
    font-size: 33px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: 0.17px;
    color: #1c1b21;
  }
  .thankYouText {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.1px;
    color: #1c1b21;
  }
  .completeButton {
    width: 350px;
  }

  .completeButtonContainer {
    height: 50px;
    width: 400px;
    margin: auto;
    margin-top: 35px;
  }
}

.expandBtnExpanded {
  position: relative;
  &:after {
    display: none;
  }
}

@include media(375px) {
  .cardContainer {
    margin: 0;
    flex: 0 0 100%;
  }
}

@include media(600px) {
  .col {
    max-width: 100%;
    flex: 0 0 100%;
    &:first-of-type {
      margin-bottom: 0;
    }
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .creditcardText {
    font-size: 16px;
  }

  .billLaterIcon {
    width: 34.14px;
    height: 24px;
    margin-right: 15px;
  }
}

@include media(700px) {
  .root {
    &:before {
      width: calc(100% - 20px);
      left: 10px;
    }
  }

  .rootActive {
    padding: 0;
    &:before {
      bottom: calc(100% - 80px);
    }
  }

  .expandBtn {
    display: none;
  }
  .slideDownOuter {
    // disable slidedown
    height: auto !important;
  }

  .editBtnWrapper {
    margin-bottom: 24px;
    padding-left: 0;
  }

  .slideDownOuter {
    margin: 0 -10px;
  }

  .cardContainerContent {
    margin-top: auto;
    width: 100%;
  }

  .costContainer {
    padding: 0 10px;
  }

  .costContainer,
  .cardContainer {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
  }
}

@media #{$md-up} {
  .paymentContainer {
    border-left: 1px solid $bona-light-grey;
    border-right: 1px solid $bona-light-grey;
  }
}
