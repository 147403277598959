@import '../../../../Styles/settings';

.billing-address {
  margin-top: 32px;

  &__heading {
    margin-bottom: 16px;
    width: 100%;
  }

  &__checkbox {
    margin-top: 16px;
  }
}

.billing-address-form {
  --padding: 0 16px;

  @media #{$md-up} {
    --padding: 0;
  }

  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 16px;
  padding: var(--padding);
}

.container {
  flex: 0 0 100%;
}

.subHeading {
  font-size: 18px;
  letter-spacing: 1.4px;
  font-weight: 300;
}

.iconWrapper {
  display: flex;
  margin-bottom: 10px;
}

.icon {
  display: none;
}

.lastInputContainer {
  margin-bottom: -24px;
}

@include media(600px) {
  .container {
    padding: 0 15px;
    flex: 0 0 50%;
    :global(body.IE) & {
      max-width: calc(50% - 1px);
    }
  }

  .billToHeading {
    flex: 0 0 100%;
    :global(body.IE) & {
      max-width: 100%;
    }
  }

  .companyOrderHeading {
    flex: 0 0 100%;
    padding-bottom: 12px;
    :global(body.IE) & {
      max-width: 100%;
    }
  }
}
