@import '../../../../Styles/settings';

.company-order {
  margin-top: 8px;

  &__heading {
    margin-bottom: 16px;
    flex: 0 0 100%;
    :global(body.IE) & {
      max-width: 100%;
    }
  }
}

.company-order-form {
  --padding: 0 16px;

  @media #{$md-up} {
    --padding: 0;
  }

  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: var(--padding);
}

.container {
  flex: 0 0 100%;
}

@include media(600px) {
  .container {
    padding: 0 15px;
    flex: 0 0 50%;
    :global(body.IE) & {
      max-width: calc(50% - 1px);
    }
  }
}
