@import '../../../Styles/settings';

.root {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 24px 16px;
}

.deliverySectionHeading {
  --border: 1px solid #{$bona-light-grey};
  --padding: 24px 16px;

  @media #{$md-up} {
    --border: none;
    padding: 48px 48px 0 48px;
  }

  padding: var(--padding);
  border-bottom: var(--border);
}

.container {
  flex: 0 0 100%;
}

.subHeading {
  font-size: 18px;
  letter-spacing: 1.4px;
  font-weight: 300;
}

.iconWrapper {
  display: flex;
  margin-bottom: 10px;
}

.icon {
  display: none;
}

.fullContainer {
  padding: 0px;
  flex: 0 0 100%;
  margin-bottom: -24px;
}

.slideDownOuter {
  height: 0;
  overflow-y: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  display: block;
  margin-bottom: 16px;
}

.slideDownExpanded {
  height: auto;
}

.form-container {
  --padding: 16px 16px;

  @media #{$md-up} {
    --padding: 48px;
  }

  padding: var(--padding);
  margin-bottom: 32px;
}

.form-section {
  $gutter: 8px;

  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter;
  margin-left: -$gutter;

  &__col-5 {
    flex: 0 0 (5 / 12) * 100%;
    padding-right: $gutter;
    padding-left: $gutter;
  }

  &__col-7 {
    flex: 0 0 (7 / 12) * 100%;
    padding-right: $gutter;
    padding-left: $gutter;
  }

  &__col-12 {
    flex: 0 0 (12 / 12) * 100%;
    padding-right: $gutter;
    padding-left: $gutter;
  }
}

.shippingInfoLink {
  text-align: right;
}

@include media(600px) {
  .root {
    margin: 0 -15px;
  }

  .container {
    padding: 0 15px;
    flex: 0 0 50%;
    :global(body.IE) & {
      max-width: calc(50% - 1px);
    }
  }

  .fullContainer {
    padding: 0 15px;
    :global(body.IE) & {
      max-width: 100%;
    }
  }
  .subHeading {
    font-size: 20px;
  }
  .icon {
    display: block;
    width: 28px;
    height: 20px;
    margin-right: 15px;
  }
}
