@import '../../../Styles/settings';

.errorContainer {
  margin-top: 16px;
}

.errorMsg {
  &:last-of-type {
    margin-bottom: 0;
  }
}
