@import '../../Styles/settings';

.root {
  background: $near-white;
  padding: 24px 16px 64px 16px;
}

.header {
  display: flex;
  align-items: baseline;
}

.h1 {
  font-weight: 300;
  font-size: 25px;
  padding-right: 5px;
  margin-bottom: 0;
  line-height: 35px;
}

.paymentSection {
  max-width: 960px;
  margin: 0 auto 35px;
  background-color: white;
  transition: top 0.5s $main-timing-function, left 0.2s ease-out;
}

.paymentSectionFixed {
  // for convenience, we use !important for the animation
  top: 115px !important;
  z-index: map-get($zIndexes, 'paymentSectionFixed');
}

.sectionsWrapper {
  opacity: 1;
  transition: opacity 0.3s $main-timing-function;
}

.hideSections {
  opacity: 0;
}

.hideSectionsDone {
  overflow: hidden;
  height: 0;
}

.showSections {
  opacity: 1;
  transition: height 2s $main-timing-function, opacity 1s $main-timing-function;
}

.form {
  overflow-y: hidden;
}

.clearCartBtnWrapper {
  margin-left: auto;
}

.cartSection {
  max-width: 960px;
  margin: 0 auto 35px;
  background-color: white;
  margin-bottom: 32px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
}

.termsCheckboxHidden {
  display: none;
}

@media #{$md-up} {
  .root {
    padding: 64px 0;
  }

  .paymentSection {
    margin: 0 auto 35px;
    padding: 0 16px;
    transition: top 1s $main-timing-function, left 0.5s ease-out;
  }
}
