@import '../../../Styles/settings';

.btn {
  background: white;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 16px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s $main-timing-function;

  &:after {
    content: '';
    border: 1px solid $bona-light-grey;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: box-shadow 0.3s $main-timing-function;
  }

  *:not(.btn) + &,
  &:first-child {
    margin-top: 0;
  }
}

.selected {
  background: $lightest-blue;
  border: 1px solid $light-blue;

  &:hover:after {
    box-shadow: none;
  }
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media #{$md-up} {
  .btn {
    &:hover,
    &:focus {
      &:after {
        box-shadow: 0px 0px 0px 1px $bona-light-grey;
      }
    }
  }
}
