@import '../../../Styles/settings';

div.root {
  display: flex;
  position: relative;
  margin-bottom: 15px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
  &.hidden {
    display: none;
  }
}
.labelContainer {
  display: flex;
  white-space: pre-wrap;
  width: 100%;
}

.input {
  opacity: 0;
  position: absolute;
}

.label {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.labelInner {
  display: inline-flex;
}

.square {
  position: relative;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  border: solid 1px $bona-dark-grey;
  border-radius: 2px;
  pointer-events: none;
  transition: opacity 0.3s $main-timing-function;
  margin-right: 8px;

  &:after {
    content: ' ';
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background: $color-brand;
    left: 2px;
    top: 2px;
    transform: scale(0);
    transition: transform 0.3s $main-timing-function;
  }
}

.invalid {
  border-color: $color-error;
}

.input:checked + .square {
  &:after {
    transform: scale(1);
  }
}

.input:checked + .square + label {
  color: $color-brand;
  @include HelveticaMedium;
}

.input:disabled + .square {
  opacity: 0.1;
}

.count {
  height: 18px;
  font-size: 14px;
  display: flex;
  padding: 0 5px;
  transition: background-color 0.3s $main-timing-function;
}

.countDisabled {
  background-color: transparent;
}

@media #{$md-up} {
  .labelContainer {
    &:hover {
      .square {
        border-width: 2px;
      }

      .input:checked + .square {
        border-width: 1px;
      }
    }
  }
}
